<!-- Testimonial start -->
<section class="section bg-light" id="clients">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="text-center mb-5">
          <h5 class="text-primary text-uppercase small-title">Sobre Tayma</h5>
          <h4 class="mb-3">Qué dicen nuestros clientes</h4>
          <p>It will be as simple as occidental in fact, it will be Occidental.</p>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-12">
        <h5 class="mb-4"><i class="mdi mdi-format-quote-open text-primary h1 mr-1"></i> 3,500 + Satisfied Client
        </h5>
        <owl-carousel-o [options]="testimonialOptions" class=" testi-carousel">
          <ng-template carouselSlide *ngFor="let item of testimonialData">
            <div class="item">
              <div class="card">
                <div class="card-body p-4">
                  <p class="mb-4">{{item.message}}</p>
                  <div class="media pt-3">
                    <div class="avatar-md mr-3">
                      <span class="avatar-title rounded-circle bg-soft-primary text-primary font-16">
                        {{item.username.charAt(0)}}
                      </span>
                    </div>
                    <div class="media-body align-self-center">
                      <h5 class="font-16">{{item.username}}</h5>
                      <span>- Invoza User</span>
                    </div>
                    <div class="text-muted ml-2 align-self-end d-none d-lg-block">
                      <i class="mdi mdi-star text-warning"></i>
                      <i class="mdi mdi-star text-warning"></i>
                      <i class="mdi mdi-star text-warning"></i>
                      <i class="mdi mdi-star text-warning"></i>
                      <i class="mdi mdi-star"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
    <!-- end row -->

    <div class="row mt-5">
      <div class="col-xl-3 col-sm-6">
        <div class="client-images">
          <img src="assets/images/clients/1.png" alt="client-img" class="mx-auto img-fluid d-block">
        </div>
      </div>
      <div class="col-xl-3 col-sm-6">
        <div class="client-images">
          <img src="assets/images/clients/3.png" alt="client-img" class="mx-auto img-fluid d-block">
        </div>
      </div>
      <div class="col-xl-3 col-sm-6">
        <div class="client-images">
          <img src="assets/images/clients/4.png" alt="client-img" class="mx-auto img-fluid d-block">
        </div>
      </div>
      <div class="col-xl-3 col-sm-6">
        <div class="client-images">
          <img src="assets/images/clients/6.png" alt="client-img" class="mx-auto img-fluid d-block">
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- Testimonial end -->

<!-- Counter start -->
<section class="section bg-primary">
  <div class="container">
    <div class="row justify-content-center mb-5">
      <div class="col-lg-7">
        <div class="text-center text-white-50">
          <h4 class="text-white">La mejor herramienta de ayuda para tus eventos</h4>
          <p>To achieve this, it would be necessary to have uniform grammar, pronunciation and more common that of
            the individual languages.</p>
        </div>
      </div>
    </div>
    <div class="row" id="counter">
      <div class="col-xl-3 col-sm-6">
        <div class="text-center mt-4">
          <i-feather name="bookmark" class="icon-dual-success icons-lg"></i-feather>
          <h2 class="counter-value text-white mt-4"> <span [CountTo]="2581" [from]="10" [duration]="1"></span>
          </h2>
          <p class="font-16 text-white-50">Projects</p>
        </div>
      </div>

      <div class="col-xl-3 col-sm-6">
        <div class="text-center mt-4">
          <i-feather name="user-plus" class="icon-dual-success icons-lg"></i-feather>
          <h2 class="counter-value text-white mt-4"> <span [CountTo]="800" [from]="2" [duration]="1"></span>
          </h2>
          <p class="font-16 text-white-50">No. of Clients</p>
        </div>
      </div>

      <div class="col-xl-3 col-sm-6">
        <div class="text-center mt-4">
          <i-feather name="coffee" class="icon-dual-success icons-lg"></i-feather>
          <h2 class="counter-value text-white mt-4"> <span [CountTo]="128" [from]="608" [duration]="1"></span>
          </h2>
          <p class="font-16 text-white-50">Cups of coffee</p>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6">
        <div class="text-center mt-4">
          <i-feather name="award" class="icon-dual-success icons-lg"></i-feather>
          <h2 class="counter-value text-white mt-4"> <span [CountTo]="47" [from]="6" [duration]="1"></span>
          </h2>
          <p class="font-16 text-white-50">Awards</p>
        </div>
      </div>
    </div>
  </div>
  <!-- end container -->
</section>
<!-- Counter end -->
