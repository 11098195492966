<!--Navbar Start-->
<p-confirmDialog header="Cerrar sesión" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light"
  (window:scroll)="windowScroll()" id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="logo text-uppercase" href="/">
      <img src="assets/images/logo-light.png" class="logo-light" alt="" height="30">
      <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="30" />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
        <li class="nav-item active">
          <a routerLink="/eventos" class="nav-link">Eventos</a>
        </li>
        <li class="nav-item">
          <a routerLink="/perfil" class="nav-link">Mi Perfil</a>
        </li>
      </ul>
      <a class="btn btn-success btn-rounded navbar-btn" (click)="logout()">Cerrar sesión</a>
    </div>
  </div>
</nav>
<!-- Navbar End -->
<div class="main-content">
  <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
  <div class="container container-top">
    <h5 class="text-primary text-uppercase small-title text-center">eventos Tayma S.A.</h5>
    <h4 class="mb-3 text-center">Listado de eventos disponibles</h4>
    <p class="text-center">Éstos son los eventos actualmente disponibles con opción de reigstro online</p>
    <div class="eventos-content">
      <div class="single-event-content" *ngFor="let event of eventos">
        <div class="event-header">
          <img [src]="event.cliente.logo" alt="cliente"/>
          <span>{{event.cliente.nombre}}</span>
        </div>
        <div class="img" [ngStyle]="{'background-image': 'url(' + event.imagen + ')'}">
          <div class="available">{{event.availables}} plazas disponibles</div>
        </div>
        <div class="title">{{event.nombre}}</div>
        <div class="event-details">
            <mat-icon aria-hidden="false" aria-label="calendar">calendar_today</mat-icon>
            <div class="event-date-place">
                <div class="date">{{event.inicio | date:'dd/MM/yyyy HH:mm'}}</div>
                <div class="place">{{event.lugar}}</div>
            </div>
            <div class="event-aforo-content">
                {{event.aforo}}
                <mat-icon aria-hidden="false" aria-label="aforo">people_outline</mat-icon>
            </div>
        </div>
        <div class="description"><span>{{event.descripcion}}</span></div>
        <div class="button-content">
          <button class="button-info" routerLink="/evento/{{ event.id }}">+ INFO</button>
          <button *ngIf="event.inscrito == false && event.availables > 0" class="button-default" (click)="registerEvent(event)">REGISTRARME</button>
          <button *ngIf="event.inscrito == false && event.availables == 0" class="button-disabled" disabled>AFORO COMPLETO</button>
          <button *ngIf="event.inscrito == true" class="button-disabled" disabled>YA ESTOY REGISTRADO</button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
