 <!-- Services start -->
 <section class="section bg-light" id="services">
   <div class="container">
     <div class="row justify-content-center">
       <div class="col-lg-8">
         <div class="text-center mb-5">
           <h5 class="text-primary text-uppercase small-title">Características</h5>
           <h4 class="mb-3">Esto es Tayma</h4>
           <p>Funcionalidades creadas específicamente para la gestión de tus eventos.</p>
         </div>
       </div>
     </div>
     <div class="row">
       <div class="col-xl-4 col-sm-6" *ngFor="let item of serviceData">
         <div class="text-center p-4 mt-3">
           <div class="avatar-md mx-auto mb-4">
             <span class="avatar-title rounded-circle bg-soft-primary">
               <i-feather name="{{item.icon}}" class="icon-dual-primary"></i-feather>
             </span>
           </div>
           <h5 class="font-18">{{item.title}}</h5>
           <p class="mb-0">{{item.text}}</p>
         </div>
       </div>

     </div>
     <!-- end row -->
   </div>
   <!-- end container -->
 </section>
 <!-- Services end -->
