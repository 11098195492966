<!-- Footer alt start -->
<section class="bg-primary py-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="float-sm-left">
          <a href="javascript: void(0);">
            <img src="assets/images/logo-light.png" alt="" height="20">
          </a>
        </div>
        <div class="float-sm-right mt-4 mt-sm-0">
          <p class="copyright-desc text-white mb-0">{{year}} © Tayma S.A. Todos los derechos reservados</p>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- Footer alt start -->
