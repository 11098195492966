import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { LocalePipe } from 'src/app/pipes/locale.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SessionService } from 'src/app/services/session.service';
import { LocalStorageUtils } from 'src/app/utils/local-storage-utils';
import { EventoDto } from 'src/app/models/event-info';

import { EventoService } from 'src/app/services/event.service';
import { ToastService, MessageType } from 'src/app/services/toast.service';
import { EventoFirebaseDto } from 'src/app/models/event-firebase-info';
import { AngularFirestore } from '@angular/fire/firestore';

export interface Evento { id: string; cliente: string; nombre: string; descripcion: string; lugar: string; imagen: string; aforo: number; inicio: any; fin: any; }
export interface Assistant { id: string; fAcceso: string; fModificacion: string; fSincronizacion: string; idCategoria: string; llegada: boolean; pAcceso: string; }

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  providers: [ConfirmationService]
})
export class EventsComponent implements OnInit {

  /**
   * Marca de operación en curso
  */
   public loaded: boolean;

  //Títulos
  title: string;
  caption: string;

  eventosFs: Evento[];

  eventos: EventoDto[] = [];
  eventosUsuario: EventoDto[] = [];
  errorMessage: any = '';
  loading: boolean;
  numEventos: number;

  constructor(private locale: LocalePipe,
    private loadingService: LoadingService,
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    public db: AngularFirestore,
    private eventoService: EventoService) { }

  ngOnInit(): void {

    // establecer no cargado hasta inicialización de componente
    this.loaded = false;

    // Añadir titulos a la cabecera
    this.title = this.locale.transform('events_title');
    this.caption = this.locale.transform('events_caption');

    // establecer cargado tras inicialización de componente
    this.loaded = true;

    this.reloadDataEventos();
  }

  /**
   * Toggle navbar
   */
   toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  /**
   * Window scroll method
   */
  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
      navbar.classList.add('nav-sticky');
    } else {
      navbar.classList.remove('nav-sticky');
    }
  }

  logout() {
    this.confirmationService.confirm({
      message: '¿Estás seguro que deseas cerrar sesión?',
      header: 'Cerrar sesión',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'CONTINUAR',
      rejectVisible: false,
      acceptButtonStyleClass: 'dialog-button',
      accept: () => {

        this.loadingService.setLoading();

          this.authService.logout(
            (resp: boolean) => {

              // ocultar loader dinámico
              this.loadingService.hideLoading();

              // actualizar sesión
              this.sessionService.saveSessionInfo(null);

              // borrar sesión persistente
              LocalStorageUtils.clear();

              // reiniciar la navegación
              this.router.navigate(['inicio']);
            },
            (resp: boolean, err: HttpErrorResponse) => {

            });
      },
      reject: () => {}
    });
  }

  reloadDataEventos() {


    this.eventoService.getEventosList(
      (resp: EventoDto[]) => {
        this.numEventos = resp.length;
        resp.sort(function sortFunction(a, b){
          return a.inicio > b.inicio ? 1 : -1;
        });
        resp = resp.filter(function(event) {
          return new Date(event.inicio).getTime() > new Date().getTime() && event.cliente.id == 46;
        });
        resp.forEach((evento) => {
          evento.inscrito = false;
          evento.availables = evento.aforo - evento.numInscritos;
        });
        this.eventoService.getEventosUsuarioList(
          (respUser: EventoFirebaseDto[]) => {
            resp.forEach((evento) => {
              respUser.forEach((eventoUsuario) => {
                if (eventoUsuario.idEvento == evento.id) {
                  evento.inscrito = true;
                }
              });
            });
            this.eventos = resp;
            this.db.collection<Evento>("eventos-pro").valueChanges().subscribe((events) => {
              events.sort(function sortFunction(a,b) {
                return a.inicio > b.inicio ? 1 : -1;
              });
              events = events.filter(function(event) {
                return (event.inicio.seconds * 1000) > Date.now();
              });
              this.eventosFs = events;
              this.eventosFs.forEach((evemt) => {
                this.db.collection("eventos-pro").doc(evemt.id).collection<Assistant>("inscritos").valueChanges().subscribe((assistants) => {
                  this.eventos.forEach((dbEvent) => {
                    if (dbEvent.id == Number(evemt.id)) {
                      dbEvent.availables = dbEvent.aforo - dbEvent.numInscritos;
                      assistants.forEach((assistant) => {
                        if (assistant.llegada == true && assistant.pAcceso == "nuevo_registro") {
                          dbEvent.availables--;
                        }
                      });
                    }
                  })
                });
              });
            });
          },
          (resp: boolean, err: HttpErrorResponse) => {

        });
        setTimeout(() => {
          this.loadingService.hideLoading();
        });
      },
      (resp: boolean, err: HttpErrorResponse) => {

      });
  }

  registerEvent(event: EventoDto) {

    this.confirmationService.confirm({
      message: '¿Quieres registrarte en el evento <b>' + event.nombre +'</b>?',
      header: 'Nuevo registro en evento',
      icon: 'pi pi-calendar-plus',
      acceptLabel: 'REGISTRARME',
      rejectVisible: false,
      acceptButtonStyleClass: 'dialog-button',
      accept: () => {

        this.loadingService.setLoading();

          this.eventoService.eventoRegistro(event.id, 
            (resp: boolean) => {

              this.loadingService.hideLoading();

              this.toastService.newMessage(MessageType.success, this.locale.transform('event_register_ok'));

              this.reloadDataEventos();

            },
            (resp: boolean, err: HttpErrorResponse) => {

              this.loadingService.hideLoading();

              this.toastService.newMessage(MessageType.danger, this.locale.transform('event_register_ko'));
            });
      },
      reject: () => {}
    });
  }
}
